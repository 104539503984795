import { useEffect, useState } from 'react';
import logo from '../images/logo.svg'
import './Login.scss'
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';
import editIcon from '../images/edit.svg'
import { useNavigate, useParams } from 'react-router-dom';

function Login() {
    const [formError, setFormError] = useState(null)
    const [formSuccess, setFormSuccess] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [loginStep, setLoginStep] = useState(1)
    const [formValues, setFormValues] = useState({
        emailAddress: '',
        otp: '',
        name: ''
    })

    const params = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        document.body.classList.add('external-page');
        return () => {
            document.body.classList.remove('external-page');
        };
    }, []);

    const handleEmailAddress = (e) => {
        setFormValues({ ...formValues, emailAddress: e.target.value })
    }

    const handleotp = (e) => {
        setFormValues({ ...formValues, otp: e.target.value })
    }

    const handleName = (e) => {
        setFormValues({ ...formValues, name: e.target.value })
    }

    const sendLoginOTP = () => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        if (!formValues.emailAddress) {
            setFormError('Please Provide Email address')
            setLoginStep(1)
        } else if (!regex.test(formValues.emailAddress)) {
            setFormError('Email address is not valid')
            setLoginStep(1)
        } else {
            let inviteId = params && params.inviteId ? params.inviteId : ''
            setIsLoading(true)
            apiServices.sendLoginOtp(formValues.emailAddress, inviteId).then((response) => {
                if (response.code) {
                    if(response.response && response.response.data && response.response.data.message){
                        setFormError(response.response.data.message)
                    } else{
                        setFormError(response.message)
                    }
                } else if (response.error) {
                    setFormError(response.message)
                } else if (!response.error && !response.code) {
                    setLoginStep(2)
                    setFormSuccess(response.message)
                }
                setIsLoading(false)
            })
        }
        setTimeout(() => {
            setFormError(null)
            setFormSuccess(null)
        }, 3000);
    }
    const verifyLoginOTP = () => {
        if (!formValues.emailAddress) {
            setLoginStep(1)
        } else {
            if (!formValues.otp) {
                setFormError('Please Provide Code')
            } else if (formValues.otp && formValues.otp.length !== 6) {
                setFormError('Code must be 6 digit')
            } else {
                setIsLoading(true)
                apiServices.verifyLoginOtp(formValues.otp, formValues.emailAddress).then((response) => {
                    if (response.code) {
                        setFormError(response.message)
                    } else if (response.error) {
                        setFormError(response.message)
                    } else if (response && !response.error && !response.code) {
                        setFormSuccess(response.message)
                        if (response && response.data && response.data.verified) {
                            localStorage.setItem("userData", JSON.stringify(response.data))
                            setTimeout(() => {
                                navigate("/dashboard")
                            }, 1000);
                        } else {
                            setLoginStep(3)
                        }
                    }
                    setIsLoading(false)
                })
            }
        }
        setTimeout(() => {
            setFormError(null)
        }, 3000);
    }
    const verifyUser = () => {
        if (!formValues.emailAddress) {
            setLoginStep(1)
            setFormError('Please enter email address')
        } else if (!formValues.otp && formValues.emailAddress) {
            setLoginStep(2)
            setFormError('Please Provide Code')
        } else if (formValues.otp && formValues.emailAddress && formValues.otp.length !== 6) {
            setLoginStep(2)
            setFormError('Code must be 6 digit')
        } else {
            if (formValues.name) {
                setIsLoading(true)
                apiServices.verifyUser(formValues.otp, formValues.emailAddress, formValues.name).then((response) => {
                    if (response.code) {
                        setFormError(response.message)
                    } else if (response.error) {
                        setFormError(response.message)
                    } else if (!response.error && !response.code && response.data && response.data.name) {
                        setFormSuccess(response.message)
                        if (response.data.verified) {
                            localStorage.setItem("userData", JSON.stringify(response.data))
                            setTimeout(() => {
                                navigate("/dashboard")
                            }, 1000);
                        } else {
                            setLoginStep(3)
                        }
                    }
                    setIsLoading(false)
                })
            } else {
                setLoginStep(3)
                setFormError('Please provide your name')
            }
        }
        setTimeout(() => {
            setFormError(null)
            setFormSuccess(null)
        }, 3000);
    }
    return (
        <div className='login'>
            <div className='logo'>
                <img src={logo} className='img-fluid' alt='WeParty' />
            </div>
            <div className={`login-box screen-${loginStep}`}>
                <h2>{loginStep === 2 ? <span className="material-icons-outlined icon" onClick={() => setLoginStep(1)}>arrow_back</span> : ''} {loginStep === 3 ? 'Almost there!' : 'Login to Continue'}</h2>
                {loginStep === 1 ? <div className='step-1'>
                    <div className="input-wrapper">
                        <label htmlFor="emailControl" className="form-label">Enter Email Address</label>
                        <input type="text" name="email_address" className="form-control" id="emailControl" placeholder=' ' autoComplete='off'
                            onChange={handleEmailAddress}
                            value={formValues.emailAddress}
                        />
                    </div>
                    <button type='submit' className={`btn ${isLoading ? 'disabled' : ''}`} onClick={sendLoginOTP}>Send OTP</button>
                </div> : loginStep === 2 ?
                    <div className='step-2'>
                        <p>We sent you mail at: <strong>{formValues.emailAddress}</strong> <img src={editIcon} alt="Edit Icon" className='edit-icon' onClick={() => setLoginStep(1)} /></p>
                        <div className="input-wrapper">
                            <label htmlFor="otpControl" className="form-label">Enter the 6-digit code</label>
                            <input type="text" name="otp" className="form-control" id="otpControl" placeholder=' ' autoComplete='off'
                                onChange={handleotp}
                                value={formValues.otp}
                            />
                        </div>
                        <button type='submit' className={`btn ${isLoading ? 'disabled' : ''}`} onClick={verifyLoginOTP}>Submit</button>
                        <p className='resend-otp' onClick={sendLoginOTP}>Text me a code again</p>
                    </div> : loginStep === 3 ?
                        <div className='step-3'>
                            <div className="input-wrapper">
                                <label htmlFor="nameControl" className="form-label">Enter your name</label>
                                <input type="text" name="name" className="form-control" id="nameControl" placeholder='e.g. Sandeep Shukla' autoComplete='off'
                                    onChange={handleName}
                                    value={formValues.name}
                                />
                            </div>
                            <button type='submit' className={`btn ${isLoading ? 'disabled' : ''}`} onClick={verifyUser}>Start using WeParty</button>
                        </div> : null}
            </div>
            {formError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{formError}</ToastMessage> : null}
            {formSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{formSuccess}</ToastMessage> : null}
        </div>
    );
}

export default Login;
