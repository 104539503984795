import SettleTransactionsTable from "./SettleTransaction";
import TransactionTable from "./TransactionTable";
import { useState } from "react";

function PriorGameDetail() {
    const [showSettleTable, setShowSettleTable] = useState(false)

    return (
        <div className='prior-games'>
            {showSettleTable ? <SettleTransactionsTable /> : <>
                <TransactionTable />
                <div className='absolute-center'><button className={`green-btn`} onClick={() => setShowSettleTable(true)}>Settle Payment</button></div>
            </>}
        </div>
    );
}

export default PriorGameDetail;
