import { useEffect, useState } from "react";
import { apiServices } from "../services";
import closeIcon from '../images/close-modal.svg'
import GameMemberRow from "./GameMemberRow";
import ToastMessage from "../components/ToastMessage";

function CreateGame({toggleModal, gameUpdated, circleData}) {
    const [gameMembers, setGameMembers] = useState([{
        id: '',
        name: '',
        buyIn: 100
    }])
    const [circleInfo, setCircleInfo] = useState(null)
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [gameName, setGameName] = useState('')

    useEffect(() => {
        if(circleData) setCircleInfo(circleData)
    }, [circleData])
    
    const handleAddField = () => {
        //setScheduleError('')
        const last_entry = gameMembers[gameMembers.length - 1]
        if((last_entry && last_entry.name && last_entry.buyIn) || gameMembers.length === 0){
            setGameMembers([
            ...gameMembers,
            { name: '', buyIn: 100 }
            ]);
        } else{
            setPageError('Please fill previous entry to add more.')
        }
        setTimeout(() => {
            setPageError(null)
        }, 3000);
    }
    const handleRemoveField = (indexToRemove) => {
        setGameMembers(gameMembers.filter((_, index) => index !== indexToRemove));
    }
    const handleGameName = (e) => {
        setGameName(e.target.value);
    }
    const handleGameMembers = (member) => {
        setGameMembers(member)
    }
    const createGame = () => {
        if(gameName === '' || gameName === null || gameName === undefined){
            setPageError('Game name is required')
        } else if(gameMembers.length === '' || (gameMembers.length === 1 && (gameMembers[0].emailAddress === '' || gameMembers[0].name === ''))){
            setPageError('Add at least one member')
        } else{
            setIsLoading(true)
            apiServices.createGame({gameName, gameMembers, circle: circleData._id}).then((response) => {
                if (response.code) {
                    if(response.response && response.response.data && response.response.data.message){
                        setPageError(response.response.data.message)
                    } else{
                        setPageError(response.message)
                    }
                } else if (response.error) {
                    setPageError(response.message)
                } else if (!response.error && !response.code) {
                    setPageSuccess(response.message)
                    setTimeout(() => {
                        toggleModal(false)
                        if(gameUpdated) gameUpdated()
                    }, 3000);
                }
                setIsLoading(false)
            })
        }
        setTimeout(() => {
            setPageError(null)
            setPageSuccess(null)
        }, 3000);
    }
    return (
        <div className='circle-modal game-modal'>
            <div className="circle-modal-wrapper">
                <img src={closeIcon} alt="close modal" className="close-modal" onClick={() => toggleModal(false)} />
                <h3>Create New Game</h3>
                <div className="circle-name">
                    <div className="input-wrapper">
                        <label htmlFor="gameNameControl" className="form-label">Enter game name</label>
                        <input type="text" name="gamename" className="form-control" id="gameNameControl" placeholder='e.g. Weekend Fun' autoComplete='off'
                            onChange={handleGameName}
                            value={gameName}
                        />
                    </div>
                    <div className="circle-members" style={{overflow: 'hidden', overflowY: gameMembers.length > 2 ? 'overlay' : 'unset'}}>
                        <div className="member-header lg-header"> 
                            <div>Add game members</div>
                            <div className="buy-in">Buy-In</div>
                        </div>
                        <div className="member-header mobile-header">
                            Add game members & Buy-In
                        </div>
                        {circleInfo ? gameMembers.map((field, index) => (
                            <GameMemberRow field={field} memberKey={index} key={index} circleInfo={circleInfo} gameMembers={gameMembers} handleGameMembers={handleGameMembers} OnHandleRemoveField={handleRemoveField} />
                        )) : null}
                    </div>
                    <div className="action-btns">
                        <span className="material-icons-outlined add-member-icon" onClick={handleAddField}>add</span>
                        <button className={`green-btn no-shadow ${isLoading ? 'disabled' : ''}`} onClick={createGame}>Create Game</button>
                    </div>
                </div>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default CreateGame;
