import { Link } from 'react-router-dom'
import dashIcon from '../images/dashboard-icon.svg'
import homeIcon from '../images/home-icon.svg'
import usersIcon from '../images/users-icon.svg'
import userIcon from '../images/user-menu-icon.svg'
import fileIcon from '../images/file-icon.svg'
import transferIcon from '../images/transfer-icon.svg'
import helpIcon from '../images/help-icon.svg'
import { useEffect, useState } from 'react'

function Sidebar({toggleMenu}) {

    const [menuOpen, setMenuOpen] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {(windowWidth >= 768 || toggleMenu) && <div className={`sidebar ${menuOpen || toggleMenu ? 'is-open' : ''}`}>
                <ul className='navbar'>
                    <li className='price'><div>Net Amount: <span className='success'>+850</span></div></li>
                    <li className='hamburger-md'><div className='hamburger' onClick={() => setMenuOpen(!menuOpen)}><img src={dashIcon} alt='Dashboard icon' /></div></li>
                    <li><Link to="/dashboard"><img src={homeIcon} alt='Home icon' /> <span className='menu-name'>My circles</span></Link></li>
                    <li><Link to="/dashboard#circleModal"><img src={usersIcon} alt='Users icon' /> <span className='menu-name'>Create a new circle</span></Link></li>
                    <li><Link to="/my-profile"><img src={userIcon} alt='User icon' /> <span className='menu-name'>Account</span></Link></li>
                    <li><Link to="#"><img src={fileIcon} alt='File icon' /> <span className='menu-name'>Feedback</span></Link></li>
                    <li><Link to="/pending-transactions"><img src={transferIcon} alt='Transfer icon' /> <span className='menu-name'>Pending Transactions</span></Link></li>
                    <li><Link to="/completed-transactions"><img src={transferIcon} alt='Transfer icon' /> <span className='menu-name'>Completed Transactions</span></Link></li>
                    <li><Link to="#"><img src={helpIcon} alt='Help icon' /> <span className='menu-name'>Help</span></Link></li>
                </ul>
            </div>}
        </>
    );
}

export default Sidebar;
