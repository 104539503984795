import deleteIcon from '../images/delete-icon.svg'

function AddExpenseRow({ field, commodityKey, commodity, handleCommodity, OnHandleRemoveField }) {
    return (
        <div key={commodityKey} className='addmember-row'>
            <div className="input-wrapper">
                <input type="text" name="name" className="form-control" autoComplete="off" placeholder="e.g. Samosa"
                    onChange={(e) => {
                        const newCommodity = [...commodity];
                        newCommodity[commodityKey].name = e.target.value;
                        handleCommodity(newCommodity);
                    }} value={field.name} />
            </div>
            <div className="input-wrapper">
                <input type="text" name="price" className="form-control" autoComplete="off" placeholder="e.g. 100"
                    onChange={(e) => {
                        const newCommodity = [...commodity];
                        newCommodity[commodityKey].price = e.target.value;
                        handleCommodity(newCommodity);
                    }} value={field.price} />
            </div>
            <span className='delete-icon' onClick={() => OnHandleRemoveField(commodityKey)}><img src={deleteIcon} alt="delete icon" /></span>
        </div>
    );
}

export default AddExpenseRow;
