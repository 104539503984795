import { useCallback, useEffect, useState } from 'react';
//import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import logo from '../images/logo.svg'
import { apiServices } from '../services';
import dashIcon from '../images/dashboard-icon.svg'

function Header({handleMenuToggle}) {

    const [userDetail, setUserDetail] = useState(null)
    const [totalEarning, setTotalEarning] = useState(null)
    const [showDropDown, setShowDropdpwn] = useState(false)
    //const navigate = useNavigate();
    const params = useParams()

    useEffect(() => {
        apiServices.getUser().then((response) => {
            setUserDetail({email: response.email, name: response.name})
        })
    }, [])

    const getTotalEarning = useCallback(() => {
        apiServices.getTotalMemberEarning().then((response) => {
            if(!response.error){
                setTotalEarning(response.totalEarning)
            }
        })
    }, [])

    const getCircleEarning = useCallback(() => {
        apiServices.getTotalMemberEarningInCircle(params.id).then((response) => {
            if(!response.error){
                setTotalEarning(response.totalEarning)
            }
        })
    }, [params.id])
    
    useEffect(() => {
        if(params.id){
            getCircleEarning()
        } else{
            getTotalEarning()
        }
    }, [getTotalEarning, getCircleEarning, params.id])

    return (
        <header className='main-header'>
            <div className='hamburger' onClick={handleMenuToggle}><img src={dashIcon} alt='Dashboard icon' /></div>
            <div className='logo'><img src={logo} className='img-fluid' alt='WeParty' /></div>
            <div className='price ms-auto'>{params.id ? 'Circle' : 'Total'} Net Amount: <span className={totalEarning > 0 ? 'success' : 'error'}>{totalEarning > 0 ? '+' : ''}{totalEarning ? totalEarning : '0'}</span></div>
            {userDetail &&
                <div className='profile' onClick={() => setShowDropdpwn(!showDropDown)}>{userDetail.name}
                    <span className='profile'>
                        {userDetail.name.split(' ').map((name) => {return name.charAt(0)}).join('')}
                    </span>
                    {showDropDown && <div className='profile-dropdown'>
                        <div onClick={() => {
                            apiServices.logout()
                            window.location.reload()
                        }}>Logout</div>
                    </div>}
                </div>
            }
        </header>
    );
}

export default Header;
