import { useState } from "react";
import { apiServices } from "../services";
import closeIcon from '../images/close-modal.svg'
import AddExpenseRow from "./AddExpenseRow";
import ToastMessage from "../components/ToastMessage";

function AddExpense({ toggleModal, gameUpdated, gameData }) {
    const [commodity, setCommodity] = useState([{
        name: '',
        price: 0
    }])
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [buyerInfo, setBuyerInfo] = useState({ name: '', id: '' })
    const [filteredUsers, setFilteredUsers] = useState([])

    const handleAddField = () => {
        //setScheduleError('')
        const last_entry = commodity[commodity.length - 1]
        if ((last_entry && last_entry.name && last_entry.price) || commodity.length === 0) {
            setCommodity([
                ...commodity,
                { name: '', price: 0 }
            ]);
        } else {
            setPageError('Please add previous commodity first.')
        }
        setTimeout(() => {
            setPageError(null)
        }, 3000);
    }
    const handleRemoveField = (indexToRemove) => {
        setCommodity(commodity.filter((_, index) => index !== indexToRemove));
    }
    const handleCommodity = (data) => {
        setCommodity(data)
    }
    const addExpense = () => {
        if (buyerInfo.name === '' || buyerInfo.name === null || buyerInfo.name === undefined) {
            setPageError('Buyer name is required')
        } else if (commodity.length === '' || (commodity.length === 1 && (commodity[0].price === '' || commodity[0].name === ''))) {
            setPageError('Add at least one commodity')
        } else {
            apiServices.addCommodity({ buyerId: buyerInfo.id, commodity, game: gameData._id, circle: gameData.circle }).then((response) => {
                if (!response.error && !response.code) {
                    setPageSuccess(response.message)
                    setTimeout(() => {
                        toggleModal(false)
                        if (gameUpdated) gameUpdated()
                    }, 3000);
                }
                if (response.error) setPageError(response.error)
                if (response.code) {
                    if(response.response && response.response.data && response.response.data.message){
                        setPageError(response.response.data.message)
                    } else{
                        setPageError(response.message)
                    }
                } 
            })
        }
        setTimeout(() => {
            setPageError(null)
            setPageSuccess(null)
        }, 3000);
    }
    return (
        <div className='circle-modal game-modal expense-modal'>
            <div className="circle-modal-wrapper">
                <img src={closeIcon} alt="close modal" className="close-modal" onClick={() => toggleModal(false)} />
                <h3>Add Expense</h3>
                <div className="circle-name">
                    <div className="input-wrapper dropdown-input">
                        <label htmlFor="buyerNameControl" className="form-label">Buyer Name</label>
                        <input type="text" name="buyername" className="form-control" id="buyerNameControl" placeholder='e.g. Pawan Sharma' autoComplete='off'
                            onChange={(e) => {
                                const filteredMembers = gameData.buyIn.filter((buyin) => {
                                    return buyin.member.name.toLowerCase().includes(e.target.value.toLowerCase())
                                })
                                setFilteredUsers(filteredMembers)
                                setBuyerInfo({ buyerInfo, name: e.target.value })
                            }} value={buyerInfo.name} />
                        {filteredUsers && <ul className="input-dropdown" style={{top: '30px'}}>
                            {filteredUsers.map((user, filteredindex) =>
                                <li key={filteredindex} onClick={() => {
                                    setBuyerInfo({ name: user.member.name, id: user.member._id })
                                    setFilteredUsers(null)
                                }}>{user.member.name}</li>
                            )}
                        </ul>}
                    </div>
                    <div className="circle-members" style={{ overflow: 'hidden', overflowY: commodity.length > 2 ? 'overlay' : 'unset' }}>
                        <div className="member-header lg-header">
                            <div>Commodity Name</div>
                            <div className="buy-in">Price</div>
                        </div>
                        <div className="member-header mobile-header">
                            Commodity Name & Price
                        </div>
                        {commodity.map((field, index) => (
                            <AddExpenseRow field={field} commodityKey={index} key={index} commodity={commodity} handleCommodity={handleCommodity} OnHandleRemoveField={handleRemoveField} />
                        ))}
                    </div>
                    <div className="action-btns">
                        <span className="material-icons-outlined add-member-icon" onClick={handleAddField}>add</span>
                        <button className="green-btn no-shadow" onClick={addExpense}>Add</button>
                    </div>
                </div>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default AddExpense;
