import { useCallback, useEffect, useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';
import profileImg from '../images/profile-thumb.svg'
import cameraIcon from '../images/camera-icon.svg'

function Profile() {
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userImage, setUserImage] = useState(null)
    const [userImageToShow, setUserImageToShow] = useState(null)
    const [isDragging, setIsDragging] = useState(false)
    const [menuToggle, setMenuToggle] = useState(false)

    const getUser = useCallback(() => {
        apiServices.getUserInfo().then((response) => {
            if(!response.error){
                setUserInfo(response.data)
                setUserName(response.data.name)
                setUserEmail(response.data.email)
                setUserImageToShow(response.data.profileImage)
            } else{
                setPageError('There is some issue in fetching user.')
            }
        })
    }, [])
    
    useEffect(() => {
        getUser()
    }, [getUser])

    const handleEmail = (e) => {
        setUserEmail(e.target.value)
    }

    const handleName = (e) => {
        setUserName(e.target.value)
    }
    const onDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const onDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if(file){
            setUserImage(file);
            const reader = new FileReader();
            reader.onload = function (e) {
                setUserImageToShow(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    }

    const saveUserDetails = (e) => {
        e.preventDefault()
        const formData = new FormData();
        if(userImage !== null) formData.append('userImage', userImage);
        formData.append('userData', JSON.stringify({email: userEmail, name: userName}))
        apiServices.updateUser(formData).then((response) => {
            if(!response.error){
                setPageSuccess(response.message)
                getUser()
            }
            if(response.error){
                setPageError(response.message)
            }
        })
    }
    const handleMenuToggle = () => {
        setMenuToggle(!menuToggle)
    }

    return (
        <div className='main'>
            <Header handleMenuToggle={handleMenuToggle} />
            <div className='page-wrapper'>
                <Sidebar toggleMenu={menuToggle} />
                <div className='page-content profile-page'>
                    <div className='profile-wrapper'>
                        <h2>Account Setting</h2>
                        <form className='profile-box' method='post'>
                            <div className='profile-image'>
                                <div className='files-wrapper'>
                                    <div className={`file-input-wrapper ${isDragging ? 'is-dragging' : ''} ${userImage || (userInfo && userInfo.userImage) ? 'files-added' : ''}`}
                                        onDragEnter={onDragEnter}
                                        onDragOver={onDragEnter}
                                        onDragLeave={onDragLeave}
                                        onDrop={onDrop}>
                                        <input type="file" name="userImage" className="form-control file-control" id="userImageControl"
                                        onChange={(e) => handleFileUpload(e)}
                                        />
                                    </div>
                                    <div className='file-item'>
                                        <div className='image'>
                                            <img src={userImageToShow ? userImageToShow : profileImg} alt='Member' />
                                        </div>
                                        <img src={cameraIcon} alt='Camera' className='camera-icon' />
                                    </div>
                                </div>
                            </div>
                            <div className='form'>
                                <div className="input-wrapper">
                                    <label htmlFor="nameControl" className="form-label">Name</label>
                                    <input type="text" name="name" className="form-control" id="nameControl" placeholder='e.g. Sandeep Shukla' autoComplete='off'
                                    onChange={handleName}
                                    value={userName}
                                    />
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="emailControl" className="form-label">Email Address</label>
                                    <input type="text" name="email" className="form-control" id="emailControl" placeholder='e.g. pawansharma12@gmail.com' autoComplete='off'
                                    onChange={handleEmail}
                                    value={userEmail}
                                    />
                                </div>
                                <button type='submit' className={`green-btn no-shadow ${userInfo && userInfo.name === userName && userInfo.email === userEmail && !userImage ? 'disabled' : ''}`} onClick={saveUserDetails}>Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default Profile;
