import React, { createContext, useContext } from 'react';

// Create the context
const MemberContext = createContext();

// Create a provider component
export const MemberProvider = ({ children, memberUpdated }) => {
  return (
    <MemberContext.Provider value={memberUpdated}>
      {children}
    </MemberContext.Provider>
  );
};

// Create a custom hook to use the context
export const useMemberContext = () => {
  return useContext(MemberContext);
};