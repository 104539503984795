import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { apiServices } from "./services";

function decodeToken(token) {
    const tokenParts = token.split('.');
    const payload = tokenParts[1];

    if (payload) {
        const decodedPayload = atob(payload);
        const parsedPayload = JSON.parse(decodedPayload);
        return parsedPayload;
    }

    return null;
}

function PrivateRoute() {
    const [auth, setAuth] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        function startLogoutTimer(expirationTime) {
            const timeRemaining = expirationTime - Date.now();
            setTimeout(() => {
                apiServices.logout()
                navigate('/')
            }, timeRemaining - 5000);
        }
        apiServices.getUser().then((response) => {
            if (response && response.token) {
                const decodedToken = decodeToken(response.token);
                const expirationTime = decodedToken.exp * 1000;
                if (expirationTime < Date.now()) {
                    apiServices.logout()
                    navigate('/')
                } else {
                    if (expirationTime && !(expirationTime < Date.now())) {
                        startLogoutTimer(expirationTime)
                        setAuth({ isAuthenticated: true });
                    }
                }
            } else {
                apiServices.logout()
                navigate('/')
            }
        }).catch((error) => {
            apiServices.logout()
            navigate('/')
        })
    }, [navigate])
    if (auth && auth.isAuthenticated) {
        return <Outlet />
    } else {
    }
}
export default PrivateRoute;