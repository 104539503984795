import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';
import profileThumb from '../images/profile-thumb.svg'
import circleImg from '../images/circle.svg'
import AddCircleMembers from './AddCircleMembers';
import { useMemberContext } from './MemberContext';
import deleteIcon from '../images/delete-icon.svg'

function CircleMembers() {

    const [membersData, setMembersData] = useState(null)
    const [showMemberModal, setShowMemberModal] = useState(false)
    const [pageError, setPageError] = useState(null)
    const memberUpdated = useMemberContext();

    const params = useParams()

    useEffect(() => {
        apiServices.getCircleMembers(params.id).then((response) => {
            if (!response.error && !response.code) setMembersData(response.data)
            if (response.error) setPageError(response.message)
            if (response.code) setPageError(response.message)
        })
    }, [params.id])

    const handleMemberUpdated = () => {
        apiServices.getCircleMembers(params.id).then((response) => {
            if (!response.error && !response.code) setMembersData(response.data)
            if (response.error) setPageError(response.message)
            if (response.code) setPageError(response.message)
            memberUpdated()
        })
    }

    const handleMemberModalToggle = (toggle) => {
        setShowMemberModal(toggle)
    }

    const removeMember = (memberId, circleId) => {
        apiServices.removeCircleMembers(memberId, circleId).then((response) => {
            if (!response.error && !response.code) handleMemberUpdated()
            if (response.error) setPageError(response.message)
            if (response.code) setPageError(response.message)
            memberUpdated()
        })
    }

    return (
        <div className='game-members'>
            {membersData && membersData.length > 0 ? <table className='table game-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Net Balance</th>
                        <th style={{maxWidth: '50px'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {membersData.map((member, index) => <tr key={index}>
                        <td>
                            <div className='thumb-column'>
                                <div className='image'>
                                    <img src={member.user.profileImage ? member.user.profileImage : profileThumb} alt={member.user.name} className='img-fluid' />
                                </div>
                                <div className='text'>
                                    <span>{member.user.name}</span>
                                </div>
                            </div>
                        </td>
                        <td className={member.totalNet > 0 ? 'success' : 'error'}>{member.totalNet > 0 ? '+' : ''}{member.totalNet}</td>
                        <td style={{maxWidth: '50px'}}><img src={deleteIcon} alt="delete icon" style={{cursor: 'pointer'}} onClick={() => removeMember(member.user._id, params.id)} /></td>
                    </tr>)}
                </tbody>
            </table> : <div className='no-data'>
                <div className='no-data-img'>
                    <img src={circleImg} className='img-fluid' alt='Circle' />
                </div>
                <p>Add Members</p>
            </div>}
            <div className='absolute-center'><button className="green-btn" onClick={() => handleMemberModalToggle(true)}>Add Members</button></div>
            {showMemberModal && <AddCircleMembers toggleModal={handleMemberModalToggle} memberUpdated={handleMemberUpdated} circleId={params.id} existingMembers={membersData} />}
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
        </div>
    );
}

export default CircleMembers;
