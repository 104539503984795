import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import Circle from "./Circle/Circle";
import PrivateRoute from "./PrivateRoute";
import PriorGames from "./Circle/PriorGames";
import CircleMembers from "./Circle/CircleMembers";
import OngoingGames from "./Circle/OngoingGames";
import PendingTransactions from "./Transactions/Transactions";
import CompletedTransactions from "./Transactions/CompletedTransactions";
import Profile from "./Profile/Profile";
import PriorGameDetail from "./Circle/PriorGameDetails";

function AppRouter() {
    const userData = localStorage.getItem('userData');
    return (
        <Routes>
            <Route path='/' element={userData ? <Navigate to="/dashboard" /> : <Login />} />
            <Route path='/:inviteId' element={<Login />} />
            <Route element={<PrivateRoute />}>
                <Route path='/dashboard' element={<Dashboard />} />
            </Route>
            <Route element={<PrivateRoute />}>
                <Route path='/circle/:id' element={<Circle />}>
                    <Route path='' element={<OngoingGames />} />
                    <Route path='prior-games'>
                        <Route index element={<PriorGames />} />
                        <Route path=':gameId' element={<PriorGameDetail />} />
                    </Route>
                    <Route path='circle-members' element={<CircleMembers />} />
                </Route>
                <Route path='pending-transactions' element={<PendingTransactions />} />
                <Route path='completed-transactions' element={<CompletedTransactions />} />
                <Route path='my-profile' element={<Profile />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
