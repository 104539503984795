import { useEffect, useState } from 'react';
import profileThumb from '../images/profile-thumb.svg'
import minusIcon from '../images/minus-icon.svg'
import plusIcon from '../images/plus-icon.svg'
import crossIcon from '../images/cross.svg'
import infoIcon from '../images/info-icon.svg'
import editIcon from '../images/edit-icon.svg'
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';

function OngoingGameRow({ buyIn, gameId, ongoinRowUpdated, handleStackUpdate, stacksUpdated, isEditor }) {
    const [newBuyIn, setNewBuyIn] = useState('100')
    const [stackValue, setStackValue] = useState('')
    const [showUpdateBuyIn, setShowUpdateBuyIn] = useState(false)
    const [editBuyIn, setEditBuyIn] = useState(false)
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [tooltip, setTooltip] = useState(false)
    const [netPrice, setNetPrice] = useState('0')

    useEffect(() => {
        if(buyIn) setNetPrice(parseFloat(buyIn.expense) - (parseFloat(buyIn.price.reduce((a, b) => parseFloat(a) + parseFloat(b), 0))) + parseFloat(stackValue !== '' ? stackValue : 0))
    }, [buyIn, stackValue])

    useEffect(() => {
        if(buyIn && buyIn.stack !== '' && buyIn.stack !== undefined  && buyIn.stack !== null) setStackValue(buyIn.stack)
    }, [buyIn])

    const handlePriceIncrease = () => {
        const currentBuyIn = parseFloat(newBuyIn) || 100;
        setNewBuyIn((currentBuyIn + 100).toString());
    }

    const handlePriceDecrease = () => {
        const currentBuyIn = parseFloat(newBuyIn) || 100;
        if(currentBuyIn > 100){
            setNewBuyIn((currentBuyIn - 100).toString());
        }
    }

    const handleUpdateBuyIn = () => {
        if(newBuyIn === '' || newBuyIn === null || newBuyIn === undefined){
            setPageError('Add some price')
        } else if(newBuyIn.toString() < 100){
            setPageError('Add at least 100')
        } else{
            apiServices.updatebuyInPrice({newBuyIn, gameId, memberId: buyIn.member._id, editBuyIn}).then((response) => {                
                if (!response.error && !response.code) setPageSuccess(response.message)
                if (response.error) setPageError(response.error)
                if (response.code) {
                    if(response.response && response.response.data && response.response.data.message){
                        setPageError(response.response.data.message)
                    } else{
                        setPageError(response.message)
                    }
                }
                setTimeout(() => {
                    setPageError(null)
                    setEditBuyIn(false)
                    setNewBuyIn('0')
                    setPageSuccess(null)
                    if(ongoinRowUpdated) ongoinRowUpdated()
                    setShowUpdateBuyIn(false)
                }, 3000);
            })
        }
    }

    return (
        <>
            {buyIn && <tr>
                <td>
                    <div className='thumb-column'>
                        <div className='image'>
                            <img src={buyIn.member.profileImage ? buyIn.member.profileImage : profileThumb} alt='Poker' />
                        </div>
                        <div className='text'>
                            <span>{buyIn.member.name} {buyIn.isCreator === '1' ? <span className='host'>H</span> : null}</span>
                        </div>
                    </div>
                </td>
                <td className={`${netPrice && netPrice <= 0 ? 'error' : 'success'}`}>{netPrice >= 0 ? '+' : ''}{netPrice ? netPrice : 0}</td>
                <td><input type='text' className={`form-control ${stacksUpdated ? 'no-border' : ''}`} value={stackValue} onChange={(e) => {
                    if(handleStackUpdate) {
                        const newNetPrice = parseFloat(buyIn.expense) - (parseFloat(buyIn.price.reduce((a, b) => parseFloat(a) + parseFloat(b), 0))) + parseFloat(e.target.value)
                        setNetPrice(newNetPrice)
                        handleStackUpdate(e.target.value, buyIn.member._id, newNetPrice)
                    }
                    setStackValue(e.target.value)
                }} readOnly={stacksUpdated ? true : false} /></td>
                <td className={`${buyIn.expense >= 0 ? 'success' : 'error'}`}>{buyIn.expense >= 0 ? '+' : ''} {buyIn.expense}</td>
                <td className='relative-box'>
                    <div style={{position: 'relative'}}>{buyIn.price.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)} <img src={infoIcon} alt="Info" className='tooltip-icon' onClick={() => setTooltip(!tooltip)} />
                    {tooltip && <div className='tooltip-drop'>
                        {buyIn.price.map((price, index) => <div key={index}>
                            <span>{index + 1}<sup>{index === 0 ? 'st' : index === 1 ? 'nd' : index === 2 ? 'rd' : 'th'}</sup> time</span>
                            <span style={{marginRight: index === buyIn.price.length - 1 ? '0' : '19px'}}>{price} {index === buyIn.price.length - 1 && <img src={editIcon} alt="Edit" className='edit-icon'
                                onClick={() => {
                                    setEditBuyIn(true)
                                    setShowUpdateBuyIn(true)
                                    setTooltip(false)
                                    setNewBuyIn(buyIn.price[buyIn.price.length - 1])
                                }
                            } />}</span>
                        </div>)}
                    </div>}
                    </div>
                    <div className='link' onClick={() => setShowUpdateBuyIn(true)}>Add more</div>
                    {showUpdateBuyIn && <div className='buyIn-box'>
                        <div className="input-wrapper number-input">
                            <span className="minus" onClick={() => handlePriceDecrease()}><img src={minusIcon} alt="Minus" /></span>
                            <input type="text" name="buyIn" autoComplete="off" className="form-control" id="buyInControl" readOnly value={newBuyIn} />
                            <span className="plus" onClick={() => handlePriceIncrease()}><img src={plusIcon} alt="Plus" /></span>
                        </div>
                        <button className="green-btn no-shadow" onClick={handleUpdateBuyIn}>Add</button>
                        <img src={crossIcon} alt="Cross" className="close-icon" onClick={() => {
                            setShowUpdateBuyIn(false)
                            setEditBuyIn(false)
                            setNewBuyIn('100')
                        }
                        } />
                    </div>}
                </td>
            </tr>}
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </>
    );
}

export default OngoingGameRow;
