import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { apiServices } from '../services';
import CreateGame from './CreateGame';
import ToastMessage from '../components/ToastMessage';
import { MemberProvider } from './MemberContext';

function Circle() {

    const [circleData, setCircleData] = useState(null)
    const [showGameModal, setShowGameModal] = useState(false)
    const [menuToggle, setMenuToggle] = useState(false)
    const [pageError, setPageError] = useState(null)
    const [circleCounts, setCircleCounts] = useState(null)

    const params = useParams()

    useEffect(() => {
        apiServices.getCircleById(params.id).then((response) => {
            if (!response.error && !response.code) setCircleData(response.data)
            if (response.error) setPageError(response.error)
            if (response.code) {
                if(response.response && response.response.data && response.response.data.message){
                    setPageError(response.response.data.message)
                } else{
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }, [params.id])

    useEffect(() => {
        apiServices.getCircleCounts(params.id).then((response) => {
            if (!response.error) setCircleCounts(response.counts)
        })
    }, [params.id])

    const memberUpdated = () => {
        apiServices.getCircleCounts(params.id).then((response) => {
            if (!response.error) setCircleCounts(response.counts)
        })
    }

    const handleGameModalToggle = (toggle) => {
        setShowGameModal(toggle)
    }

    const getGames = useCallback(() => {
        apiServices.getCircles().then((response) => {
            if (!response.error) {
                //setCircleList(response.data)
            } else {
                setPageError('There is some issue in fetching circles.')
            }
        })
    }, [])

    useEffect(() => {
        getGames()
    }, [getGames])

    const gameUpdated = () => {
        getGames()
    }
    const handleMenuToggle = () => {
        setMenuToggle(!menuToggle)
    }

    return (
        <div className='main'>
            <Header handleMenuToggle={handleMenuToggle} />
            <div className='page-wrapper'>
                <Sidebar toggleMenu={menuToggle} />
                <div className='page-content circle-page'>
                <MemberProvider memberUpdated={memberUpdated}>
                    <div className='page-header' style={{ background: `${circleData && circleData.gradient && circleData.gradient.length === 2 ? `linear-gradient(95.77deg, ${circleData.gradient[0]} 3.78%, ${circleData.gradient[1]} 97.89%)` : '#f5f5f5'}` }}>
                        {circleData && <h1>{circleData.name}</h1>}
                        <ul>
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} end to="">Ongoing Game ({circleCounts ? circleCounts.onGoingGames : 0})</NavLink></li>
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="prior-games">Prior Games ({circleCounts ? circleCounts.priorGames : 0})</NavLink></li>
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="circle-members">Members ({circleCounts ? circleCounts.circleMembers : 0})</NavLink></li>
                        </ul>
                    </div>
                    <Outlet />
                </MemberProvider>
                    {/*<div className='no-data'>
                        <div className='no-data-img'>
                            <img src={circleImg} className='img-fluid' alt='Circle' />
                        </div>
                        <p>Create a game to get started</p>
                    </div>
                    <table className='table game-table'>
                        <tr>
                            <th>Name</th>
                            <th>Net</th>
                            <th>Stack</th>
                            <th>Expense</th>
                            <th>Buy-In</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td>-300</td>
                            <td><input type='text' /></td>
                            <td>0</td>
                            <td>
                                300<br />
                                Add more
                            </td>
                        </tr>
                    </table>*/}
                </div>
            </div>
            {showGameModal && <CreateGame toggleModal={handleGameModalToggle} gameUpdated={gameUpdated} circleData={circleData} />}
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
        </div>
    );
}

export default Circle;
