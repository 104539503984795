import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { apiServices } from '../services';
import CreateGame from './CreateGame';
import ToastMessage from '../components/ToastMessage';
import circleImg from '../images/circle.svg';
import PriorGamesRow from './PriorGamesRow';

function PriorGames() {

    const [circleData, setCircleData] = useState(null)
    const [gamesData, setGamesData] = useState(null)
    const [showGameModal, setShowGameModal] = useState(false)
    const [pageError, setPageError] = useState(null)

    const params = useParams()

    useEffect(() => {
        if (circleData === undefined || circleData === null) {
            apiServices.getCircleById(params.id).then((response) => {
                if (!response.error) setCircleData(response.data)
            })
        }
    }, [params.id, circleData])

    const handleGameModalToggle = (toggle) => {
        setShowGameModal(toggle)
    }

    const getGames = useCallback(() => {
        apiServices.getCirclePriorGames(params.id).then((response) => {
            if (!response.error && !response.code) setGamesData(response.data)
            if (response.error) setPageError(response.error)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
        })
    }, [params.id])

    useEffect(() => {
        getGames()
    }, [getGames])

    return (
        <div className='prior-games'>
            {gamesData && gamesData.length > 0 ? <table className='table game-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Members</th>
                    </tr>
                </thead>
                <tbody>
                    {gamesData.map((game, index) => <PriorGamesRow key={index} game={game} />)}
                </tbody>
            </table> : <div className='no-data'>
                <div className='no-data-img'>
                    <img src={circleImg} className='img-fluid' alt='Circle' />
                </div>
                <p>No prior games available yet</p>
            </div>}
            <div className='absolute-center'><button className="green-btn" onClick={() => handleGameModalToggle(true)}>Create New Game</button></div>
            {showGameModal && circleData && <CreateGame toggleModal={handleGameModalToggle}
                //gameUpdated={gameUpdated}
                circleData={circleData} />}
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
        </div>
    );
}

export default PriorGames;
