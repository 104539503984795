import dashImg from '../images/dashboard.svg'
import AddCircle from './AddCircle';
import { useCallback, useEffect, useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';
import { useLocation, useNavigate } from 'react-router-dom';

function Dashboard() {
    const [showCircleModal, setShowCircleModal] = useState(false)
    const [menuToggle, setMenuToggle] = useState(false)
    const [pageError, setPageError] = useState(null)
    const [circleList, setCircleList] = useState([])
    const  navigate = useNavigate()
    const  location = useLocation()

    const handleCircleModalToggle = (toggle) => {
        setShowCircleModal(toggle)
    }

    useEffect(() => {
        if (location.hash === '#circleModal') {
            handleCircleModalToggle(true);
        } else {
            handleCircleModalToggle(false);
        }
    }, [location]);

    const getCircles = useCallback(() => {
        apiServices.getCircles().then((response) => {
            if(!response.error){
                setCircleList(response.data)
            } else{
                setPageError('There is some issue in fetching circles.')
            }
        })
    }, [])
    
    useEffect(() => {
        getCircles()
    }, [getCircles])

    const circleUpdated = () => {
        getCircles()
    }
    const handleMenuToggle = () => {
        setMenuToggle(!menuToggle)
    }

    return (
        <div className='main'>
            <Header handleMenuToggle={handleMenuToggle} />
            <div className='page-wrapper'>
                <Sidebar toggleMenu={menuToggle} />
                <div className='page-content dashboard-page'>
                    {circleList && circleList.length > 0 ?
                        <div className='circles-box'>
                            {circleList.map((circle, index) => 
                                <div className='circle' key={index} style={{ background: `${circle.gradient && circle.gradient.length === 2 ? `linear-gradient(95.77deg, ${circle.gradient[0]} 3.78%, ${circle.gradient[1]} 97.89%)` : '#f5f5f5'}` }} onClick={() => navigate(`/circle/${circle._id}`)}>
                                    <h4>{circle.name}</h4>
                                    <p>Members: {circle.members ? circle.members.length : 0}</p>
                                </div>
                            )}
                        </div>
                    : <div className='no-data'>
                        <div className='no-data-img'>
                            <img src={dashImg} className='img-fluid' alt='Dashboard' />
                        </div>
                        <p>Create or join a circle to get started</p>
                    </div>}
                    <div className='absolute-center'><button className="green-btn" onClick={() => handleCircleModalToggle(true)}>Create new Circle</button></div>
                </div>
            </div>
            {showCircleModal && <AddCircle toggleModal={handleCircleModalToggle} circleUpdated={circleUpdated} />}
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
        </div>
    );
}

export default Dashboard;
