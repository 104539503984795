import { Link } from 'react-router-dom';
import pokerIcon from '../images/poker.svg'

function PriorGamesRow({game}) {

    const formatDate = (date1) => {
        const date = new Date(date1);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getUTCDate();
        const month = monthNames[date.getUTCMonth()];
        const year = date.getUTCFullYear().toString().slice(-2);
        const formattedDate = `${day}, ${month}' ${year}`;
        return formattedDate;
    }

    return (
        <tr>
            <td>
                <div className='thumb-column'>
                    <div className='image'>
                        <img src={pokerIcon} alt='Poker' />
                    </div>
                    <div className='text'>
                        <span>{game.name}</span>
                        <Link className='link' to={`${game._id}`} style={{display: 'block'}}>View details</Link>
                    </div>
                </div>
            </td>
            <td>{formatDate(game.createdAt)}</td>
            <td>{game.buyIn ? game.buyIn.length : 0}</td>
        </tr>
    );
}

export default PriorGamesRow;
