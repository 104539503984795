import React, { useEffect, useState } from 'react'
import '../scss/toasts.scss'

function ToastMessage({icon, color, children, duration = 3000}) {
  const [showToast, setShowToast] = useState(true);
  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowToast(false);
    }, duration);
    return () => {
      clearTimeout(hideTimeout);
    };
  }, [duration]);
  const hideToast = () => {
    setShowToast(false);
  };
  return (
    <>
      {showToast && (
        <div className={`toast toast-${color} fade-in-up ${showToast ? '' : 'hide'}`}>
          <span className={`icon material-icons-outlined`}>{icon}</span>
          {children}<span className='icon material-icons-outlined close' onClick={hideToast}>close</span>
        </div>
      )}
    </>
  )
}

export default ToastMessage