import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useCallback, useEffect, useState } from 'react';
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';
import profileThumb from '../images/profile-thumb.svg'

function PendingTransactions() {
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [transactionData, setTransactionData] = useState(null)
    const [menuToggle, setMenuToggle] = useState(false)

    const getNonsettledTransactions = useCallback(() => {
        apiServices.getMemberNonSettledTransactions().then((response) => {
            if (!response.error && !response.code) {
                setTransactionData(response.transactions)
            }
            if (response.error && response.message) setPageError(response.message)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }, [])

    useEffect(() => {
        getNonsettledTransactions()
    }, [getNonsettledTransactions])

    const settleTransaction = (targetMember) => {
        apiServices.settleUserTransactions(targetMember).then((response) => {
            if (!response.error && !response.code) {
                setPageSuccess(response.message)
                getNonsettledTransactions()
            }
            if (response.error) setPageError(response.error)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
                setPageSuccess(null)
            }, 3000);
        })
    }
    const handleMenuToggle = () => {
        setMenuToggle(!menuToggle)
    }

    return (
        <div className='main'>
            <Header handleMenuToggle={handleMenuToggle} />
            <div className='page-wrapper'>
                <Sidebar toggleMenu={menuToggle} />
                <div className='page-content transactions-page'>
                    <h2>Pending Transactions</h2>
                    <table className='table game-table'>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Actions</th>
                        </tr>
                        {transactionData && transactionData.length > 0 ? transactionData.map((transaction, index) => <tr key={index}>
                            <td>
                                <div className='thumb-column'>
                                    <div className='image'>
                                    <img src={transaction.member.profileImage ? transaction.member.profileImage : profileThumb} alt={transaction.member.name} className='img-fluid' />
                                    </div>
                                    <div className='text'>
                                        <span>{transaction.member.name}</span>
                                    </div>
                                </div>
                            </td>
                            <td className={transaction.totalAmount > 0 ? 'success' : 'error'}>{transaction.totalAmount > 0 ? '+' : ''}{transaction.totalAmount}</td>
                            <td><button className='green-btn bordered-btn' onClick={() => settleTransaction(transaction.member.id)}>Settle</button></td>
                        </tr>) : <tr><td colSpan="3" style={{textAlign: 'center'}}>All the transactions are settled!</td></tr>}
                    </table>
                </div>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default PendingTransactions;
