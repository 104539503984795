import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';
import circleImg from '../images/circle.svg';
import OngoingGameRow from './OngoingGameRow';
import AddExpense from './AddExpense';
import CreateGame from './CreateGame';
import { useMemberContext } from './MemberContext';

function OngoingGames() {

    const [gameData, setGameData] = useState(null)
    const [circleData, setCircleData] = useState(null)
    const [showGameModal, setShowGameModal] = useState(false)
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [showExpenseModal, setShowExpenseModal] = useState(false)
    const [stacks, setStacks] = useState([])
    const [stacksUpdated, setStacksUpdated] = useState(false)
    const [isEditor, setIsEditor] = useState(false)

    const params = useParams()
    const navigate = useNavigate()
    const memberUpdated = useMemberContext();

    useEffect(() => {
        apiServices.getCircleOngoingGame(params.id).then((response) => {
            if (!response.error && !response.code) setGameData(response.data)
            if (response.error) setPageError(response.error)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }, [params.id])

    useEffect(() => {
        if (gameData && gameData.buyIn) {
            const filterCreator = gameData.buyIn.filter((bin) => {
                return bin.isCreator === '1'
            })
            const userEmail = JSON.parse(localStorage.getItem('userData')).email
            if (userEmail && filterCreator && filterCreator.length > 0 && filterCreator[0].member.email === userEmail) setIsEditor(true)
        }
    }, [gameData])

    const gameUpdated = () => {
        apiServices.getCircleOngoingGame(params.id).then((response) => {
            if (!response.error && !response.code) setGameData(response.data)
            if (response.error) setPageError(response.error)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }

    const handleExpenseModalToggle = (toggle) => {
        setShowExpenseModal(toggle)
    }

    useEffect(() => {
        if (circleData === undefined || circleData === null) {
            apiServices.getCircleById(params.id).then((response) => {
                if (!response.error && !response.code) setCircleData(response.data)
                if (response.error) setPageError(response.error)
                if (response.code) {
                    if (response.response && response.response.data && response.response.data.message) {
                        setPageError(response.response.data.message)
                    } else {
                        setPageError(response.message)
                    }
                }
                setTimeout(() => {
                    setPageError(null)
                }, 3000);
            })
        }
    }, [params.id, circleData])

    const handleGameModalToggle = (toggle) => {
        setShowGameModal(toggle)
    }

    const handleStackUpdate = (value, buyerId, netPrice) => {
        setStacks(prevStacks => {
            const newStacks = [...prevStacks];
            const index = newStacks.findIndex(stack => stack.buyerId === buyerId);
            if (index !== -1) {
                newStacks[index].value = value;
                newStacks[index].netPrice = netPrice;
            } else {
                newStacks.push({ buyerId, value, netPrice });
            }
            const nonEmptyStacks = newStacks.filter((fltrdstack) => fltrdstack.value !== '' && fltrdstack.value !== null && fltrdstack.value !== undefined)
            return nonEmptyStacks;
        });
    }
    const handleEndGame = () => {
        const totalStackValue = stacks.reduce((accumulator, current) => {
            return accumulator + Number(current.value);
        }, 0);
        const totalBuyInValue = gameData.buyIn.reduce((accumulator, current) => {
            const priceSum = current.price.reduce((sum, price) => sum + Number(price), 0);
            return accumulator + priceSum;
        }, 0);
        if (gameData && gameData.buyIn && gameData.buyIn.length !== stacks.length) {
            setPageError('Please update all stacks')
        } else if (totalBuyInValue !== totalStackValue) {
            setPageError('Stack value exceed total BuyIn')
        } else {
            const gameId = gameData._id
            apiServices.updateStacks(stacks, gameData._id, params.id).then((response) => {
                if (!response.error && !response.code) {
                    setPageSuccess(response.message)
                    memberUpdated()
                }
                if (response.error) setPageError(response.error)
                if (response.code) {
                    if (response.response && response.response.data && response.response.data.message) {
                        setPageError(response.response.data.message)
                    } else {
                        setPageError(response.message)
                    }
                }
                gameUpdated()
                setTimeout(() => {
                    navigate(`prior-games/${gameId}`)
                }, 1000);
            })
        }
        setTimeout(() => {
            setPageError(null)
            setPageSuccess(null)
        }, 3000);
    }

    useEffect(() => {
        if (gameData && gameData.buyIn) {
            const stacksReflected = gameData.buyIn.every(item => item.stack !== '' && item.stack !== undefined && item.stack !== null);
            setStacksUpdated(stacksReflected)
        }
    }, [gameData])

    return (
        <div className='ongoing-game'>
            {gameData && gameData.name && <h3>{gameData.name}</h3>}
            {!gameData ? <div className='no-data'>
                <div className='no-data-img'>
                    <img src={circleImg} className='img-fluid' alt='Circle' />
                </div>
                <p>Create a game to get started</p>
            </div> :
            <div className='ongoing-table'>
                <table className='table game-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Net</th>
                            <th>Stack</th>
                            <th>Expense</th>
                            <th>Buy-In</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gameData.buyIn.map((buyin, index) => <OngoingGameRow isEditor={isEditor} buyIn={buyin} key={index} gameId={gameData._id} ongoinRowUpdated={gameUpdated} handleStackUpdate={handleStackUpdate} stacksUpdated={stacksUpdated} />)}
                    </tbody>
                </table>
            </div>}
            {!gameData ? <div className='absolute-center'><button className="green-btn" onClick={() => handleGameModalToggle(true)}>Create New Game</button></div> : <div className='absolute-center'><button className="green-btn" onClick={() => handleExpenseModalToggle(true)}>Add Expense</button><button className={`red-btn ${gameData.buyIn.length !== stacks.length ? 'disabled' : ''}`} onClick={handleEndGame}>End Game</button></div>}
            {showExpenseModal && <AddExpense toggleModal={handleExpenseModalToggle}
                gameUpdated={gameUpdated}
                gameData={gameData}
            />}
            {showGameModal && circleData && <CreateGame toggleModal={handleGameModalToggle}
                gameUpdated={gameUpdated}
                circleData={circleData} />}
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default OngoingGames;
