import { useEffect, useState } from "react";
import closeIcon from '../images/close-modal.svg'
import ToastMessage from "../components/ToastMessage";
import { apiServices } from "../services";
import AddMemberRow from "../Dashboard/AddMemberRow";

function AddCircleMembers({ toggleModal, memberUpdated, circleId, existingMembers }) {
    const [pageError, setPageError] = useState(null)
    const [pageSuccess, setPageSuccess] = useState(null)
    const [usersList, setUsersList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [circleMembers, setCircleMembers] = useState([{
        id: '',
        emailAddress: '',
        memberName: ''
    }])

    const handleAddField = () => {
        const last_entry = circleMembers[circleMembers.length - 1]
        if ((last_entry && last_entry.emailAddress && last_entry.memberName) || circleMembers.length === 0) {
            setCircleMembers([
                ...circleMembers,
                { emailAddress: '', memberName: '' }
            ]);
        } else {
            setPageError('Please fill previous entry to add more.')
        }
        setTimeout(() => {
            setPageError(null)
        }, 3000);
    }
    const handleRemoveField = (indexToRemove) => {
        setCircleMembers(circleMembers.filter((_, index) => index !== indexToRemove));
    }
    const handleCircleMembers = (member) => {
        setCircleMembers(member)
    }
    useEffect(() => {
        const getUsers = () => {
            apiServices.getUsers().then((response) => {
                if (response.code) {
                    if(response.response && response.response.data && response.response.data.message){
                        setPageError(response.response.data.message)
                    } else{
                        setPageError(response.message)
                    }
                } else if (response.error) {
                    setPageError(response.message)
                } else if (!response.error && !response.code) {
                    const emailsToRemove = existingMembers && existingMembers.length > 0 ? existingMembers.map((item) => item.email) : [];
                      
                    const filteredData = response.data.filter(item => !emailsToRemove.includes(item.email));
                    setUsersList(filteredData)
                }
            })
        }
        getUsers()
    }, [existingMembers])
    const addMembers = () => {
        if (circleMembers.length === '' || (circleMembers.length === 1 && (circleMembers[0].emailAddress === '' || circleMembers[0].name === ''))) {
            setPageError('Add at least one member')
        } else {
            setIsLoading(true)
            apiServices.addCircleMembers(circleMembers, circleId).then((response) => {
                if (response.code) {
                    setPageError(response.message)
                } else if (response.error) {
                    setPageError(response.message)
                } else if (!response.error && !response.code) {
                    setPageSuccess(response.message)
                    setTimeout(() => {
                        toggleModal(false)
                        if(memberUpdated) memberUpdated()
                    }, 2000);
                }
                setIsLoading(false)
            })
        }
        setTimeout(() => {
            setPageError(null)
            setPageSuccess(null)
        }, 3000);
    }
    return (
        <div className='circle-modal'>
            <div className="circle-modal-wrapper fade-in-up-modal">
                <img src={closeIcon} alt="close modal" className="close-modal" onClick={() => toggleModal(false)} />
                <h3>Add Members</h3>
                <div className="circle-name">
                    <div className="circle-members">
                        <div className="member-header">
                            <div>Enter Email Address</div>
                            <div>Enter Member Name</div>
                        </div>
                        {circleMembers.map((field, index) => (
                            <AddMemberRow field={field} key={index} memberkey={index} usersList={usersList} OnHandleRemoveField={handleRemoveField} circleMembers={circleMembers} handleCircleMembers={handleCircleMembers} />
                        ))}
                        <div className="action-btns">
                            <span className="material-icons-outlined add-member-icon" onClick={handleAddField}>add</span>
                            <button className={`green-btn no-shadow ${isLoading ? 'disabled' : ''}`} onClick={addMembers}>Add Members</button>
                        </div>
                    </div>
                </div>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
            {pageSuccess !== null ? <ToastMessage color="green" duration={3000} icon="check_circle">{pageSuccess}</ToastMessage> : null}
        </div>
    );
}

export default AddCircleMembers;
