import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

//Auth APIs

let sendLoginOtp = async (credentials, inviteId) => {
    let userCred = {
        emailAddress: credentials.toLowerCase(),
        inviteId
    }
    return await api.post(`/auth/loginotp`, userCred).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const verifyLoginOtp = async (otp, emailAddress) => {
    return await api.post(`/auth/verifylogin`, { otp, emailAddress }).then((response) => {
        if (response) {
            return response.data
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const verifyUser = async (otp, emailAddress, name) => {
    return await api.post(`/auth/verifyuser`, { otp, emailAddress, name }).then((response) => {
        if (response) {
            return response.data
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getUser = async () => {
    const userInfo = await localStorage.getItem('userData');
    if (userInfo) {
        return JSON.parse(userInfo)
    }
}

const getUserInfo = async () => {
    const token = await getToken()
    return await api.get(`/user/fetchuser`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        if (response) {
            return response.data
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

let updateUser = async (data) => {
    const token = await getToken()
    return await api.post(`/user/editprofile`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getToken = async () => {
    const user = await getUser();
    if (user && user['token']) {
        const decodedToken = JSON.parse(window.atob(user['token'].split('.')[1]));
        if (decodedToken.exp * 1000 > Date.now()) {
            return user['token']
        }
    } else {
        localStorage.getItem('userData') && localStorage.removeItem('userData')
    }
}

const getUsers = async () => {
    const token = await getToken()
    return await api.get(`/user/fetchusers`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        if (response) {
            return response.data
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const logout = () => {
    localStorage.removeItem("userData");
};

//Circle APIs

let createCircle = async (data) => {
    const token = await getToken()
    return await api.post(`/circle/addcircle`, data, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getCircles = async () => {
    const token = await getToken()
    return await api.get(`/circle/fetchcircles`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        if (response) {
            return response.data
        }
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

let getCircleById = async (id) => {
    const token = await getToken()
    return await api.post(`/circle/fetchcirclebyid`, { circleId: id }, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getCircleCounts = async (circleId) => {
    const token = await getToken()
    return await api.get(`/circle/getcirclecounts/${circleId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const GetCircleTransactions = async (circleId, gameId) => {
    const token = await getToken()
    return await api.get(`/circle/getcircletransactions?circleId=${circleId}&gameId=${gameId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const saveTransactionDetails = async (data) => {
    const token = await getToken()
    return await api.post(`/circle/savetransactions`, data, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

//Game APIs
const createGame = async (data) => {
    const token = await getToken()
    return await api.post(`/game/addgame`, data, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const addCommodity = async (data) => {
    const token = await getToken()
    return await api.post(`/game/addcommodity`, data, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const updatebuyInPrice = async (data) => {
    const token = await getToken()
    return await api.post(`/game/updatebuyinprice`, data, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const updateStacks = async (data, id, circleId) => {
    const token = await getToken()
    return await api.post(`/game/updatestacks`, { stacks: data, gameId: id, circleId }, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getCircleOngoingGame = async (circleId) => {
    const token = await getToken()
    return await api.get(`/game/fetchongoinggame/${circleId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getCirclePriorGames = async (circleId) => {
    const token = await getToken()
    return await api.get(`/game/fetchpriorgames/${circleId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getCircleGameDetails = async (circleId, gameId) => {
    const token = await getToken()
    return await api.get(`/game/fetchGameDetails?circleId=${circleId}&gameId=${gameId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

// Member APIs

const getCircleMembers = async (circleId) => {
    const token = await getToken()
    return await api.get(`/circle/getmembers/${circleId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const addCircleMembers = async (members, circleId) => {
    const token = await getToken()
    return await api.post(`/circle/addcirclemembers`, { members, circleId }, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const removeCircleMembers = async (memberId, circleId) => {
    const token = await getToken()
    return await api.post(`/circle/removecirclemembers`, { memberId, circleId }, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getTotalMemberEarning = async () => {
    const token = await getToken()
    return await api.get(`/game/fetchtotalearning`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getTotalMemberEarningInCircle = async (circleId) => {
    const token = await getToken()
    return await api.get(`/game/fetchtotalcirclelearning/${circleId}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getMemberNonSettledTransactions = async () => {
    const token = await getToken()
    return await api.get(`/circle/getnonsettledtransactions`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const getMemberSettledTransactions = async () => {
    const token = await getToken()
    return await api.get(`/circle/getsettledtransactions`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

const settleUserTransactions = async (targetMember) => {
    const token = await getToken()
    return await api.post(`/circle/settleusertransaction`, { targetMember }, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.data
    }).catch(function (error) {
        if (error) {
            return error
        }
    });
}

export const apiServices = {
    sendLoginOtp, verifyLoginOtp, verifyUser, getToken, getUser, getUserInfo, updateUser, getUsers, logout, createCircle, getCircles, getCircleById, getCircleCounts, GetCircleTransactions, saveTransactionDetails, createGame, addCommodity, updatebuyInPrice, updateStacks, getCircleOngoingGame, getCirclePriorGames, getCircleGameDetails, getCircleMembers, addCircleMembers, getTotalMemberEarning, getTotalMemberEarningInCircle, removeCircleMembers, getMemberNonSettledTransactions, getMemberSettledTransactions, settleUserTransactions
}
