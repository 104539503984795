import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useCallback, useEffect, useState } from 'react';
import { apiServices } from '../services';
import ToastMessage from '../components/ToastMessage';
import profileThumb from '../images/profile-thumb.svg'

function CompletedTransactions() {
    const [pageError, setPageError] = useState(null)
    const [transactionData, setTransactionData] = useState(null)
    const [menuToggle, setMenuToggle] = useState(false)

    const getSettledTransactions = useCallback(() => {
        apiServices.getMemberSettledTransactions().then((response) => {
            if (!response.error && !response.code) {
                setTransactionData(response.transactions)
            }
            if (response.error && response.message) setPageError(response.message)
            if (response.code) {
                if (response.response && response.response.data && response.response.data.message) {
                    setPageError(response.response.data.message)
                } else {
                    setPageError(response.message)
                }
            }
            setTimeout(() => {
                setPageError(null)
            }, 3000);
        })
    }, [])

    useEffect(() => {
        getSettledTransactions()
    }, [getSettledTransactions])

    const formatDateTime = (dateToFormat) => {
        const isoString = dateToFormat;

        // Parse the ISO string into a Date object
        const date = new Date(isoString);

        // Format the time
        const optionsTime = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        const timeFormatter = new Intl.DateTimeFormat('en-US', optionsTime);
        const formattedTime = timeFormatter.format(date);

        // Format the date
        const optionsDate = {
            day: 'numeric',
            month: 'short',
            year: '2-digit',
        };

        const dateFormatter = new Intl.DateTimeFormat('en-US', optionsDate);
        const formattedDate = dateFormatter.format(date);

        // Combine the formatted time and date
        const formattedDateTime = `${formattedTime}, ${formattedDate.replace(' ', '’ ')}`;
        return formattedDateTime
    }
    const handleMenuToggle = () => {
        setMenuToggle(!menuToggle)
    }

    return (
        <div className='main'>
            <Header handleMenuToggle={handleMenuToggle} />
            <div className='page-wrapper'>
                <Sidebar toggleMenu={menuToggle} />
                <div className='page-content transactions-page'>
                    <h2>Completed Transactions</h2>
                    <table className='table game-table'>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Time & Date</th>
                        </tr>
                        {transactionData && transactionData.length > 0 ? transactionData.map((transaction, index) => <tr key={index}>
                            <td>
                                <div className='thumb-column'>
                                    <div className='image'>
                                        <img src={transaction.member.profileImage ? transaction.member.profileImage : profileThumb} alt={transaction.member.name} className='img-fluid' />
                                    </div>
                                    <div className='text'>
                                        <span>{transaction.member.name}</span>
                                    </div>
                                </div>
                            </td>
                            <td className={transaction.totalAmount > 0 ? 'success' : 'error'}>{transaction.totalAmount > 0 ? '+' : ''}{transaction.totalAmount}</td>
                            <td>{formatDateTime(transaction.settledAt)}</td>
                        </tr>) : <tr><td colSpan="3" style={{ textAlign: 'center' }}>There are no completed transactions!</td></tr>}
                    </table>
                </div>
            </div>
            {pageError !== null ? <ToastMessage color="red" duration={3000} icon="highlight_off">{pageError}</ToastMessage> : null}
        </div>
    );
}

export default CompletedTransactions;
